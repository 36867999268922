/* loading page */
.loading-container{
	width: 100%;
	height: 100%;
}
.loading{
	width: 131px;
	height: 80px;
	position: relative;
	top: 50%;
	left: 50%;    
	transform: translate(-50%, -50%);
	background: url('../../public/logo/42logo-loading.svg') -130px 0 / 260px no-repeat;
}
.loading::after {
	content: '';
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;    
	width: 0px;
	height: 80px;
	background: url('../../public/logo/42logo-loading.svg') 0 0 / 260px no-repeat;
	transition: all 1s ease-in-out;
}
.animate::after{ width: 130px; }
