@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import "./styles/reset.css"; */
@import "./styles/common.css";
/* @import "./styles/common-media.css"; */
/* @import "./styles/tournament.css"; */
/* @import "./styles/user.css"; */

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
   /* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
  }
}

@layer components {
  .bg-basic {
	  @apply bg-no-repeat bg-contain bg-center
  }
  .side-hidden {
	  @apply fixed top-0 -left-96
  }
  .side-shown {
	  @apply fixed top-0 left-0
  }
  .button-unavailable {
	  @apply opacity-30 border border-black cursor-not-allowed
  }
  .button-inactive {
	  @apply opacity-100 border border-[#86868b]
  }
  .button-active {
	  @apply opacity-100 border-2 border-[#0071e3]
  }
  .tip-hidden {
	  @apply invisible
  }
  .tip-shown {
	  @apply visible
  }
  .is-required {
	  @apply after:content-['*'] after:ml-0.5 after:text-red-500
  }
  .bg-color-xbox {
	  @apply bg-[#00C83B]
  }
  .bg-color-switch {
	  @apply bg-[#EA2A36]
  }
  .bg-color-ps5{
	  @apply bg-[#527FD8]
  }
  .tab-selected {
	  @apply shadow-selected
  }
  .tab-not-selected {
	  @apply shadow-not-selected opacity-35
  }
  .slot-empty {
	  @apply bg-[#F4F6F8] text-xl font-semibold text-gray-400 pointerhover:hover:brightness-75 cursor-pointer
  }
  .slot-full {
	  @apply bg-white text-xl font-extrabold pointerhover:hover:brightness-75 cursor-alias
  }
  .slot-selected-xbox {
	  @apply text-[#00C83B] font-Bolwby-One text-2xl font-normal pointerhover:hover:brightness-75 cursor-pointer
  }
  .slot-selected-switch {
	  @apply text-[#EA2A36] font-Bolwby-One text-2xl font-normal pointerhover:hover:brightness-75 cursor-pointer
  }
  .slot-selected-ps5 {
	  @apply text-[#527FD8] font-Bolwby-One text-2xl font-normal pointerhover:hover:brightness-75 cursor-pointer
  }
  .slot-elapsed {
		@apply bg-[#C6C6C6] text-xl font-semibold text-gray-500 cursor-not-allowed
	}
  .booking-btn-active-xbox {
	  @apply bg-[#00C83B] visible
  }
  .booking-btn-active-switch {
	  @apply bg-[#EA2A36] visible
  }
  .booking-btn-active-ps5 {
	  @apply bg-[#527FD8] visible
  }
  .search-hidden {
	  @apply h-0
  }
  .search-shown {
	  @apply h-full
  }
  .popup-hidden {
	  @apply opacity-0
  }
  .popup-shown {
	  @apply opacity-100
  }
  .modal-hidden {
	  @apply hidden
  }
  .modal-shown {
	  @apply flex
  }
  .font-color-xbox {
	  @apply text-[#00C83B]
  }
  .font-color-switch {
	  @apply text-[#EA2A36]
  }
  .font-color-ps5 {
	  @apply text-[#527FD8]
  }
  .booking-xbox {
	  @apply text-[#00C83B] font-Bolwby-One text-lg
  }
  .booking-switch {
	  @apply text-[#EA2A36] font-Bolwby-One text-lg
  }
  .booking-ps5 {
	  @apply text-[#527FD8] font-Bolwby-One text-lg
  }
  .admin-tab-selected {
	  @apply text-white bg-black
  }
  .admin-slot-selected {
	  @apply text-white bg-gray-600
  }
  .admin-mode-selected {
	  @apply bg-gray-600 text-white
  }
  .admin-btn-unavailable {
	  @apply opacity-30 cursor-not-allowed
  }
  .admin-report-status-active {
	  @apply border-[#00C83B] bg-[#00C83B]
  }
  .admin-report-status-inactive {
	  @apply border-[#D2D2D2] bg-white
  }
  .page-dot-now {
	  @apply bg-black
  }
  .page-dot {
	  @apply bg-[#D9D9D9]
  }
}

#root {
  height: 100%;
}
