/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #38b791 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #38b791;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

h3 {
	font-size: 20px;
	font-weight: bold;
	padding-bottom : 5px;
	border-bottom: 2px solid black;
}

.selected {
	color: white !important;
	font-weight: bold;
	background-color: lightskyblue !important;
}

.container {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	padding: 20px;
	box-sizing: border-box;
	height: 100%;
	display: flex;
	flex-direction: column;
  }
  
  .header {
	flex-shrink: 0;
  }
  
  .dashboard-content {
	background-color: white;
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	padding: 30px;
	max-height: 80%;
	border-radius: 10px;
  }

  .none { 
	display: none !important;
  }
  
  .header {
	text-align: center;
	margin-bottom: 20px;
  }
  
  .header img {
	max-width: 100%;
	height: auto;
  }
  
  .section {
	margin-bottom: 30px;
  }
  
  .section h3 {
	margin-bottom: 10px;
  }
  
  .options {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
  }
  
  .option {
	background-color: #f1f1f1;
	border-radius: 5px;
	color: #333;
	padding: 20px 30px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	margin: 5px;
	cursor: pointer;
  }

  .option.long {
	flex: 100%;
  }

  .sml-option {
	border-radius: 5px;
	padding : 10px 12px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	cursor: pointer;
	margin: 5px;
  }
  
  .option:hover {
	background-color: #ddd;
  }

  .toggle-list {
	width: 100%;
	margin-top: 10px;
  }
  
  .toggle-item {
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 10px;
  }
  
  .toggle-header {
	background-color: #f1f1f1;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
  }
  
  .toggle-header h4 {
	margin: 0;
	font-size: 16px;
  }
  
  .toggle-icon {
	font-size: 20px;
	font-weight: bold;
  }
  
  .toggle-content {
	padding: 10px;
	overflow: hidden;
  }
  
  .toggle-content.slide-in {
	display: block;
  }
  
  .toggle-content.slide-out {
	display: none;
  }

  /* .slide-in {
	animation: 1.0s ease-out;
  }
  
  .slide-out {
	animation: 1.0s ease-out;
  }
   */


body {
font-family: Arial, sans-serif;
margin: 0;
padding: 0;
}

.dashboard {
display: flex;
justify-content: space-between;
background-color: #e0e0e0;
padding: 20px;
}

.sidebar {
background-color: #f2f2f2;
padding: 20px;
width: 200px;
border-right: 1px solid #ccc;
}

.sidebar ul {
list-style-type: none;
padding: 0;
margin: 0;
}

.sidebar li {
margin-bottom: 10px;
}

.main-content {
background-color: #fff;
padding: 20px;
flex-grow: 1;
margin-left: 20px;
border-left: 1px solid #ccc;
}

.timeline {
margin-bottom: 20px;
border-left: 2px solid #ccc;
  padding-left: 10px;
}

.timeline-item {
display: flex;
align-items: center;
margin-bottom: 10px;
}

.timeline-item .dot {
width: 10px;
height: 10px;
border-radius: 50%;
margin-right: 10px;
position: relative;
  left: -15px;
}

.timeline-item .dot.success {
background-color: green;
}

.buttons {
display: flex;
justify-content: flex-end;
}

.buttons button {
margin-left: 10px;
padding: 8px 16px;
background-color: #e0e0e0;
border: none;
cursor: pointer;
}

.buttons button:last-child {
background-color: #ff6666;
color: #fff;
}